<template>
  <div>
    <breadcrumb name="Inquiry Types" title="Inquiry" subtitle="Edit" @onTitleClicked="$emit('cancel')"/>

    <vx-card :title="inquiry">
      <vs-row>
        <vs-col vs-lg="6" vs-sm="12">
          <label for="name_en" class="input">English Name</label>
          <vs-input v-model="name_en" class="input" id="name_en"/>
        </vs-col>
        <vs-col vs-lg="6" vs-sm="12">
          <label for="name_ar" class="input">Arabic Name</label>
          <vs-input v-model="name_ar" class="input" id="name_ar"/>
        </vs-col>
        <vs-col vs-lg="6" vs-sm="12">
          <label for="mobile_active" class="input">Show on Mobile?</label>
          <vs-switch v-model="mobile_active" id="mobile_active" class="input"/>
        </vs-col>
      </vs-row>
      <div class="optionBtns">
        <vs-button
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="$emit('cancel')"
        >Cancel
        </vs-button>
      </div>

    </vx-card>


  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    components: { Breadcrumb },
    name: "edit-inquiry",
    props: {
      item: {
        required: true,
        default: () => {
        }
      }
    },
    data() {
      return {
        brands: [],
        data: [],
        brand_id: "0",
        inquiry_type: "0",
        inquiry: this.item.name_en,
        name_en: this.item.name_en,
        name_ar: this.item.name_ar,
        mobile_active: this.item.mobile_active
      }
    },
    methods: {
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {

        this.$vs.loading();

        let payload = {
          name_en: this.name_en,
          name_ar: this.name_ar,
          mobile_active: this.mobile_active ? "1" : "0"
        };

        this.$httpAdmin.patch(`inquiry-types/${this.item.id}`, payload)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data Saved Successfully",
              color: "success"
            });
            this.$emit("saved");
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: "Couldn't Save Data!",
              color: "danger"
            });
          });

      }
    },
    watch: {
      brand_id(value) {
        for (let i in this.brands) {
          if (this.brands[i].id === value) {
            this.brand_name = this.brands[i].name_en;
            break;
          }
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .body {
    border-radius: 10px;
    padding: 20px;
  }

  .optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }

  .input {
    width: 98%;
    margin-left: 20px;
  }

  .emails {
    height: 175px;
  }

  label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
  }

  select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
  }
</style>
