<template>
  <div>
    <edit-inquiry v-if="edit && !add" :item="item" @saved="getData" @cancel="edit = false"/>
    <add-inquiry v-if="add && !edit" @saved="getData" @cancel="add= false"/>
    <div v-if="!edit && !add">
      <breadcrumb name="Inquiry Types" title="All Inquiries" subtitle="List"/>

      <div id="data-list-thumb-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          @search="handleSearch"
          @sort="handleSort"
          search
          :data="inquiries"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <div class="flex flex-wrap-reverse items-center data-list-btn-container">
              <add-new-button @click="addNewData"/>
            </div>

            <!-- ITEMS PER PAGE -->
            <table-header
              :current-page="currentPage"
              :items-per-page="itemsPerPage"
              :length="inquiries.length"
              :queried-items="queriedItems"
              @onChangeItemsPerPage="itemsPerPage = $event"
            />
          </div>

          <template slot="thead">
            <vs-th sort-key="name_en">English Name</vs-th>
            <vs-th sort-key="name_ar">Arabic Name</vs-th>
            <vs-th sort-key="description_en">Status</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <string-row :data="tr.name_en"/>
              <string-row :data="tr.name_ar"/>
              <status-row :data="tr.mobile_active"/>
              <actions-row
                :deletable="deletable"
                @onEditClicked="editData(tr)"
                @onDeleteClicked="deleteData(tr.id)"/>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      <vs-pagination :total="totalItems" v-model="page"/>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from "@/components/general/breadcrumb";
  import StringRow from "@/components/table/rows/string-row";
  import HtmlRow from "@/components/table/rows/html-row";
  import AddNewButton from "@/components/form/addnew-button";
  import StatusRow from "@/components/table/rows/status-row";
  import ActionsRow from "@/components/table/rows/actions-row";
  import TableHeader from "@/components/table/table-header";
  import AddInquiry from "./add-inquiry";
  import EditInquiry from "./edit-inquiry";

  export default {
    name: "inquiry-list",
    components: {
      EditInquiry,
      AddInquiry,
      Breadcrumb,
      StringRow,
      HtmlRow,
      AddNewButton,
      StatusRow,
      ActionsRow,
      TableHeader
    },
    data() {
      return {
        items: [],
        item: {},
        itemsPerPage: 20,
        isMounted: false,
        page: 1,
        totalItems: 0,
        query: "",
        sortKey: null,
        sortActive: null,
        edit: false,
        add: false,
        inquiries: [],
        deletable: true
      };
    },
    methods: {
      getData() {
        this.$vs.loading();
        this.add = false;
        this.edit = false;
        const queryString = `per_page=${this.itemsPerPage}&page=${this.page}&query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}`;
        this.$httpAdmin.get(`inquiry-types?${queryString}`)
          .then(r => {
            this.inquiries = r.data.data;
            console.log({ inquiry_types: this.inquiries });
            // this.totalItems = Math.ceil(r.data.data.total / this.itemsPerPage);
            this.$vs.loading.close();
          })
          .catch(() => {
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't Load Data!",
              color: "danger"
            });
            this.$vs.loading.close();
          });
      },
      onSuccessOperation() {
        this.getData();
      },
      addNewData() {
        this.add = true;
      },
      confirmDeleteData(id) {
        this.$vs.dialog({
          type: "confirm",
          accept: () => this.deleteData(id),
          title: "Confirm",
          text: "You're going to delete this notification"
        });
      },
      deleteData(id) {
        this.$vs.loading();
        this.$httpAdmin.delete(`inquiry-types/${id}`)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Successful operation",
              text: "Data has been deleted successfully",
              color: "success"
            });
            this.getData();
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't delete data from the server",
              color: "danger"
            });
          });
      },
      editData(data) {
        //this.sidebarData = data;
        //this.toggleDataSidebar(true)
        this.item = data;
        this.edit = true;
      },
      handleSearch(query) {
        this.query = query;
      },
      handleSort(key, active) {
        this.sortKey = key;
        this.sortActive = active === "asc" ? 1 : 0;
      }
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      queriedItems() {
        return this.$refs.table
          ? this.$refs.table.queriedResults.length
          : this.inquiries.length;
      }
    },
    watch: {
      page() {
        this.getData();
      },
      query() {
        this.getData();
      },
      sortKey() {
        this.getData();
      },
      sortActive() {
        this.getData();
      },
      itemsPerPage() {
        this.getData();
      },
      inquiries() {
        if (this.inquiries.length === 1) {
          this.deletable = false;
        } else {
          this.deletable = true;
        }
      }
    },
    mounted() {
      this.getData();
      this.isMounted = true;
    }
  };
</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {
      /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }

            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
